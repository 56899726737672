@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap');


body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  /* background-color: red; */
}

.navbar {
  /* width: calc(100vw - 260px); */
  width: 100vw;
  /* box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1); */
}



.mobile-navbar {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px;
}

.active{
  background-color: #27427A;
  color: #fff;
}

.active:hover{
  background-color: #27427A !important;
}

.top-container {
  /* display: grid; */
  width: 100%;
  /* grid-template-columns: 260px 1fr; */
  min-height: 100vh;
  overflow-x: hidden;
}

.sm-container {
  /* display: grid; */
  width: 100%;
  /* grid-template-columns: 260px 1fr; */
  min-height: 100vh;
  overflow-x: hidden;
}



.sidebar{
  /* width: 260px; */
  box-shadow: 1px 0 2px -1px rgba(0, 0, 0, 0.1);
}

.minibar{
  /* width: 80px; */
  box-shadow: 1px 0 2px -1px rgba(0, 0, 0, 0.1);
}

.minibar{
  width: 80px;
  box-shadow: 1px 0 2px -1px rgba(0, 0, 0, 0.1);
}

.content{
  /* width: calc(100vw - 260px); */
  height: 100%;
  display: grid; 
  /* margin-left: 260px; */
  grid-auto-columns: 1fr; 
  grid-template-rows: 80px 1fr;
  align-content: space-around; 
  overflow: hidden;
}

.mini-content {
  /* width: calc(100vw - 80px); */
  height: 100%;
  display: grid; 
  /* margin-left: 80px; */
  grid-auto-columns: 1fr; 
  grid-template-rows: 80px 1fr;
  align-content: space-around; 
  overflow: hidden;
}


/* search input with icon */
.searchInput {
  /* position: relative; */
  background-color: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
}

.searchInput input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 17px;
}

.search_icon{
  position: absolute;
  z-index: 0;
  right: 20px;
  font-weight: bolder;
  font-size: 1.2rem;
  color: #9fa6b1;
}

.searchInput input:focus {
  /* outline: 2px solid #27427A; */
  outline: none;
}

.inputDate {
  /* padding: 10px 15px; */
}

.inputDate:focus {
  outline: 2px solid #27427A;
}

.disabled-link {
  pointer-events: none;
}
